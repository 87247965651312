import {
  getInputFieldMasterValues,
  getInputFieldValues,
} from 'apiClient/compensation/compensation';
import Select from 'components/atoms/FormElements/select/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './QueryMaker.css';
import ValueEditor from './ValueEditor';
export default function QueryMaker({
  updateInitialQuery,
  initialQuery,
  fields,
  operators,
}) {
  //   const [fields, setFields] = useState([]);
  //   const [operators, setOperators] = useState({});
  const [valueOptions, setValueOptions] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const [query, setQuery] = useState({
    combinator: 'and',
    rules: [],
  });

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);
  //   useEffect(() => {
  //     if (
  //       savedQuery &&
  //       Object.keys(savedQuery || {}).length !== 0 &&
  //       fields?.length > 0 &&
  //       Object.keys(operators)?.length > 0
  //     ) {
  //       setQuery(
  //         queryBuilderFormatterForUI(dataIndex, savedQuery, fields, operators),
  //       );
  //     }
  //   }, [savedQuery, fields, operators]);

  useEffect(() => {
    if (updateInitialQuery) {
      updateInitialQuery(query);
    }
  }, [query]);
  const fetchedFieldsRef = useRef(new Set());
  const traverseRules = (rules) => {
    const newFetchedFields = new Set(fetchedFieldsRef.current);

    rules?.forEach(({ field, rules }) => {
      // Check if the field needs to fetch values
      if (
        field?.label &&
        !valueOptions[field.label] &&
        !newFetchedFields.has(field.label)
      ) {
        newFetchedFields.add(field.label);
        if (field.value !== 'user') {
          fetchValues(field);
        }
      }
      // Recursively check nested rules
      if (rules && rules.length > 0) {
        traverseRules(rules); // Recursively traverse nested rules
      }
    });

    fetchedFieldsRef.current = newFetchedFields;
  };

  const fetchValues = (field) => {
    if (field?.value) {
      setLoadingFields((prev) => ({ ...prev, [field.label]: true }));
      if (field?.forConditionBuilder) {
        getInputFieldMasterValues(field.value)
          .then((res) => {
            setValueOptions((prev) => ({
              ...prev,
              [field.label]: res?.data || [],
            }));
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoadingFields((prev) => ({ ...prev, [field.label]: false }));
          });
      } else {
        getInputFieldValues(field.value)
          .then((res) => {
            setValueOptions((prev) => ({
              ...prev,
              [field.label]: res?.data || [],
            }));
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoadingFields((prev) => ({ ...prev, [field.label]: false }));
          });
      }
    }
  };

  useEffect(() => {
    traverseRules(query?.rules);
  }, [query?.rules, valueOptions]);

  // Formatting functions
  const formatFields = (data) => {
    return data.map((field) => ({
      ...field,
      label: field.alias || field.value,
      name: field.id,
      value: field.id,
    }));
  };

  const formatValueOptions = (data) => {
    return data.map((field) => ({
      ...field,
      label: field.alias || field.value,
      value: field.id,
    }));
  };

  const formatOperators = (data) => {
    return data.map((field) => ({
      ...field,
      label: field.displayName,
      name: field.code,
      value: field.code,
    }));
  };

  const getFilteredOperators = (dataType) => {
    return operators[dataType] || [];
  };

  const handleQueryChange = useCallback((newQuery) => {
    setQuery(newQuery);
  }, []);

  return (
    <div>
      <QueryBuilder
        showCloneButtons
        // showLockButtons
        showShiftActions={true}
        resetOnOperatorChange
        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
        query={query}
        onQueryChange={handleQueryChange}
        getOperators={(fieldName) => {
          const dataType = fieldName;
          //   console.log(fieldName, dataType);

          if (dataType?.dataType) {
            const filteredOperators = getFilteredOperators(dataType.dataType);
            if (filteredOperators.length > 0) {
              return formatOperators(filteredOperators);
            }
          }
          return [];
        }}
        controlElements={{
          fieldSelector: (props) => (
            <>
              <Select
                options={formatFields(fields)}
                className="focus:outline-none rounded-lg w-full bg-white"
                placeholder="Select option"
                label=""
                isSearchable={true}
                value={props.value}
                onChange={(selectedOption) => {
                  props.handleOnChange(selectedOption);
                }}
              />
            </>
          ),
          valueEditor: (props) => (
            <ValueEditor
              {...props}
              fields={fields}
              valueOptions={valueOptions}
            />
          ),
          operatorSelector: (props) => (
            <>
              <Select
                options={props.options}
                className="focus:outline-none rounded-lg w-full bg-white"
                placeholder="Select option"
                label=""
                isLoading={loadingFields[props.field?.label]}
                isSearchable={true}
                isDisabled={
                  loadingFields[props.field?.label] || !props.field?.dataType
                }
                value={props?.value}
                onChange={(selectedOption) => {
                  props.handleOnChange(selectedOption);
                }}
              />
            </>
          ),
        }}
      />
    </div>
  );
}
