import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get } from '../apiCalls';

export const getFontLibrary = async (data) => {
  try {
    const res = await get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_API_KEY_GOOGLE_FONT}`,
    );

    return res.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Unexpected error');
    }
  }
};

export const fetchEmployeeData = async (searchedWord, pageIndex, pageSize) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/search-employee?department=${searchedWord}&lastName=${searchedWord}&fullName=${searchedWord}&middleName=${searchedWord}&businessEmail=${searchedWord}&subDepartment=${searchedWord}&id=${searchedWord}&firstName=${searchedWord}&jobTitle=${searchedWord}&displayName=${searchedWord}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );
    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee search failed');
    }
  }
};

export const fetchEmployeeSearch = async ({
  pageSize,
  pageIndex,
  displayName,
  department,
  lastName,
  fullName,
  middleName,
  businessEmail,
  subDepartment,
  id,
  firstName,
  jobTitle,
}) => {
  let URL = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/search-employee?pageSize=${pageSize}&pageIndex=${pageIndex}`;
  if (department) {
    URL += `&department=${department}`;
  }
  if (lastName) {
    URL += `&lastName=${lastName}`;
  }
  if (middleName) {
    URL += `&middleName=${middleName}`;
  }
  if (businessEmail) {
    URL += `&businessEmail=${businessEmail}`;
  }
  if (subDepartment) {
    URL += `&subDepartment=${subDepartment}`;
  }
  if (id) {
    URL += `&id=${id}`;
  }
  if (firstName) {
    URL += `&firstName=${firstName}`;
  }
  if (jobTitle) {
    URL += `&jobTitle=${jobTitle}`;
  }
  if (displayName) {
    URL += `&displayName=${displayName}`;
  }
  if (fullName) {
    URL += `&fullName=${fullName}`;
  }
  const response = await get(URL);
  return response.data;
};
